

import React, { useState, useEffect } from 'react';
import { Card, Button, Row, Col, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../BaseURL';
import NavHeader from './NavHeader';
import Footer from './Footer';
import Loader from './Loder'; // Import the custom Loader component
import '../css/BuyProperty.css'
import AOS from 'aos';
const BuyProperty = () => {
  const [properties, setProperties] = useState([]); // Initialize as an array
  const [loading, setLoading] = useState(true);
  const [isNavigating, setIsNavigating] = useState(false); // Track navigation state
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Sunrise Ventures"

    AOS.init({
      duration: 1000, // Duration of the animation
      easing: 'ease-in-out',
      once: true, // Animation happens only once
    });
    axios
      .get(`${BASE_URL}/addproperty/propertiesall`)
      .then((response) => {
        const data = Array.isArray(response.data.data) ? response.data.data : [];
        setProperties(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching properties:', error);
        setLoading(false);
      });
  }, []);


  const handleReadMore = (propertyName, propertyId) => {
    setIsNavigating(true); // Display the loader when navigating
    setTimeout(() => {
      navigate(`/property/${propertyName}/${propertyId}`);
    }, 2000); // Simulate loader for 2 seconds before navigation
  };


  return (
    <>
      <div className='d-flex flex-column min-vh-100'>
        <NavHeader />
        <div className="buyerproperty" data-aos="fade-down">
        <div className="header-overlay">
          <h1 className="header-title">Your Trusted Partner in Real Estate</h1>
        </div>
      </div>

        <Container className="flex-grow-1 mt-5">
          {loading ? (
            <div className="text-center">
              <p>Loading properties...</p>
            </div>
          ) : (
            <Row xs={1} md={4} className="g-4">
              {properties.length > 0 ? (
                properties.map((property) => (
                  <Col key={property.addPropertyId} data-aos="fade-up">
                    <Card className="h-100 shadow-lg" style={{ transition: 'transform 0.3s' }}>
                      <Card.Img
                        variant="top"
                        src={`${BASE_URL}/${property.primaryImage}`}
                        alt="Property"
                        style={{ height: '200px', objectFit: 'cover' }}
                      />
                      <Card.Body>
                        <Card.Title>{property.city}, {property.state}</Card.Title>
                        <Card.Text>
                          <strong>Property Type:</strong> {property.propertyType} <br />
                          {/* <strong>Price:</strong> ₹{property.expectedPrice.toLocaleString()} <br /> */}
                          <strong>Area:</strong> {property.area} {property.areaMeasurement}
                        </Card.Text>
                        <Button
                          variant="warning"
                          onClick={() => handleReadMore(property.propertyName, property.addPropertyId)} // Show loader on click
                        >
                          Read More
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              ) : (
                <p className="text-center">No properties available at the moment.</p>
              )}
            </Row>
          )}
        </Container>
        {isNavigating && <Loader />} {/* Show loader while navigating */}
      </div>
      <Footer />
    </>
  );
};

export default BuyProperty;
