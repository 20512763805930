import React, { useEffect, useState } from 'react';
import '../css/Loader.css'; // Add your custom loader CSS here
import logo from '../assests/sunriseLogo.png'
const Loader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Set a timeout to simulate the loading process
    setTimeout(() => {
      setLoading(false); // After 3 seconds, set loading to false to hide the loader
    }, 3000); // Adjust this time as needed
  }, []);

  return (
    <div className={`loader-container ${loading ? 'show' : 'hide'}`}>
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
    </div>
  );
};

export default Loader;
