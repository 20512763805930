
// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
// import axios from 'axios';
// import { BASE_URL } from '../BaseURL';  
// import '../css/Footer.css';

// export default function Footer() {
//   const [visitorCount, setVisitorCount] = useState(0);
//   const currentYear = new Date().getFullYear(); 

//   const fetchVisitorCount = async () => {
//     try {
//       const response = await axios.get(`${BASE_URL}/visitor/visitor-count`);
//       if (response.data.success) {
//         setVisitorCount(response.data.count);  
//       }
//     } catch (error) {
//       console.error("Error fetching visitor count:", error);
//     }
//   };

//   const incrementVisitorCount = async () => {
//     try {
//       const response = await axios.post(`${BASE_URL}/visitor/increment-visitor-count`);
//       if (response.data.success) {
//         setVisitorCount(response.data.count);  
//       }
//     } catch (error) {
//       console.error("Error incrementing visitor count:", error);
//     }
//   };

//   // Check if the user has already been counted
//   const checkAndIncrementVisitor = () => {
//     // If visitor is not found in localStorage, increment the count
//     if (!localStorage.getItem('visitorCounted')) {
//       incrementVisitorCount();
//       localStorage.setItem('visitorCounted', 'true'); // Mark visitor as counted in this session
//     }
//   };

//   // Fetch visitor count on page load and increment only if needed
//   useEffect(() => {
//     fetchVisitorCount();
//     checkAndIncrementVisitor();
//   }, []); // Empty dependency array ensures this runs only once when the component mounts

//   return (
//     <footer
//       style={{
//         width: '100%',
//         position: 'sticky',
//         bottom: 0,
//         zIndex: 1000,
//         boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//       }}
//       className="bg-dark text-white mt-5 py-3"
//     >
//       <Container>
//         <Row className="align-items-center justify-content-between">
//           {/* Copyright and Developer Info */}
//           <Col xs={12} sm={10} md={10} lg={10} className=" text-sm-left mb-2 mb-sm-0">
//             <p>
//               &copy; {currentYear} Sunrise Ventures. All rights reserved. | Design and Developed by:{" "}
//               <a style={{ color: "white", textDecoration: "none" }} href="https://bighostindia.in/">
//                 BigHost India Pvt. Ltd.
//               </a>
//             </p>
//           </Col>

//           {/* Visitor Count Display */}
//           <Col xs={6} sm={2} md={2} lg={2} className="text-center text-sm-right mb-2 mb-sm-0">
//             <div
//               style={{
//                 backgroundColor: '#343a40',
//                 color: 'white',
//                 padding: '5px 10px',
//                 borderRadius: '5px',
//                 fontSize: '14px',
//               }}
//               className="d-flex justify-content-center align-items-center"
//             >
//               Visitors: {visitorCount}
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     </footer>
//   );
// }





import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from '../BaseURL';
import '../css/Footer.css';

export default function Footer() {

  const currentYear = new Date().getFullYear();
  const [visitorCount, setVisitorCount] = useState(0);

  useEffect(() => {
    // Fetch the visitor count from the server
    axios.get(`${BASE_URL}/visitor/count`)
      .then((response) => {
        setVisitorCount(response.data.count);
      })
      .catch((error) => {
        console.error('Error fetching visitor count', error);
      });
  }, []);

  return (
    <footer className="bg-dark text-white mt-5 py-3">
      <Container>
        <Row className="align-items-center justify-content-between">
          <Col xs={12} sm={10} md={10} lg={10} className="text-sm-left mb-2 mb-sm-0">
            <p>
              &copy; {currentYear} Sunrise Ventures. All rights reserved. | Design and Developed by:{" "}
              <a style={{ color: "white", textDecoration: "none" }} href="https://bighostindia.in/">
                BigHost India Pvt. Ltd.
              </a>
            </p>
          </Col>

          <Col xs={6} sm={2} md={2} lg={2} className="text-center text-sm-right mb-2 mb-sm-0">
            <div
              style={{
                backgroundColor: '#343a40',
                color: 'white',
                padding: '5px 10px',
                borderRadius: '5px',
                fontSize: '14px',
              }}
            >
              Visitors: {visitorCount}
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
