

import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Button, Spinner, Modal, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../BaseURL';
import NavHeader from './NavHeader';
import Footer from './Footer';
import { toast } from 'react-toastify';
import ToastNotification from '../ToastNotification';



const PropertyDetail = () => {
  const { propertyName, addPropertyId } = useParams();
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false); // Modal state for enquiry form
  const [previewImage, setPreviewImage] = useState(null); // State for the clicked image preview
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    whatsapp: '',
  });

  useEffect(() => {
    document.title="Sunrise Ventures"
    // Fetch property details
    axios
      .get(`${BASE_URL}/addproperty/getProperty/${propertyName}/${addPropertyId}`)
      .then((response) => {
        setProperty(response.data);
        setLoading(false);

        // Dynamically change the title
        if (response.data && response.data.propertyName) {
          document.title = `${response.data.propertyName}-${response.data.address}`; // Set title dynamically
        }
      })
      .catch((error) => {
        console.error('Error fetching property details:', error);
        setLoading(false);
      });
  }, [addPropertyId, propertyName]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/addproperty/getProperty/${propertyName}/${addPropertyId}`)
      .then((response) => {
        setProperty(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching property details:', error);
        setLoading(false);
      });
  }, [addPropertyId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
  
    // Validation checks
    if (!formData.name || !formData.email || !formData.whatsapp || formData.whatsapp.length !== 10) {
      toast.error("Please fill out all fields correctly.");
      return; // If validation fails, stop further execution
    }
  
    // Structure the enquiry data
    const enquiryData = {
      ...formData,
      propertyDetails: {
        generateId: property.generateId,
        propertyType: property.propertyType,
        expectedPrice: property.expectedPrice,
        area: property.area,
        areaMeasurement: property.areaMeasurement,
        address: property.address,
        city: property.city,
        state: property.state,
        primaryImage: property.primaryImage,
        additionalImages: [
          property.additionalImage1,
          property.additionalImage2,
          property.additionalImage3
        ]
      }
    };
  
    try {
      // Make the POST request to submit the enquiry data
      const response = await axios.post(`${BASE_URL}/enquiry/submit`, enquiryData);
      console.log('Enquiry Submitted:', response.data);
  
      // Show success toast message
      toast.success("Enquiry submitted successfully!");
  
      // Close the modal after successful submission
      setShowModal(false);
  
      // Optionally, reset form data here
      setFormData({
        name: '',
        mobile: '',
        email: '',
        whatsapp: ''
      });
  
    } catch (error) {
      // Handle any errors that occur during the submission process
      console.error('Error submitting enquiry:', error);
  
      // Show error toast message
      toast.error("There was an error submitting your enquiry. Please try again.");
    }
  };


  const handleImageClick = (image) => {
    setPreviewImage(image);
  };

  const handleClosePreview = () => {
    setPreviewImage(null);
  };

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="primary" />
        <p>Loading property details...</p>
      </div>
    );
  }

  if (!property) {
    return <p className="text-center">Property not found.</p>;
  }

  return (
    <>
      <NavHeader />
      <ToastNotification/>
      <Container fluid>
        {/* Primary Image */}
        <Row className="mb-4">
          <Col>
            <div style={{ position: 'relative' }}>
              <img
                src={`${BASE_URL}/${property.primaryImage}`}
                alt="Primary"
                className="w-100"
                style={{ height: '300px', objectFit: 'cover' }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7))',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h3 style={{ color: 'white', fontWeight: 'bold' }}>
                  {property?.propertyName || 'Experience the Best in Real Estate'}
                </h3>
              </div>
            </div>
          </Col>
        </Row>

        <div style={{ padding: '0 8%' }}>
          {/* Property Details */}
          <h2 className="text-center mt-4 mb-4" style={{ fontWeight: 'bold' }}>Property Details</h2>
          <Row className="mb-4">
            <Col md={6}>
              <img
                src={`${BASE_URL}/${property.additionalImage1}`}
                alt="Additional"
                className="w-100"
                style={{ height: '300px', objectFit: 'cover' }}
                onClick={() => handleImageClick(property.additionalImage1)} // On click, preview this image
              />
            </Col>
            <Col md={6}>
            <p style={{ fontSize: '16px' }}><strong style={{fontSize:'16px'}}>Property Id:</strong> {property.generateId}</p>
              <p style={{ fontSize: '16px' }}><strong style={{fontSize:'16px'}}>Type:</strong> {property.propertyType}</p>
              <p style={{ fontSize: '16px' }}><strong style={{fontSize:'16px'}}>Price:</strong> ₹{property.expectedPrice.toLocaleString()}</p>
              <p style={{ fontSize: '16px' }}><strong style={{fontSize:'16px'}}>Area:</strong> {property.area} {property.areaMeasurement}</p>
              <p style={{ fontSize: '16px' }}><strong style={{fontSize:'16px'}}>Address:</strong> {property.address}</p>
              <p style={{ fontSize: '16px' }}><strong style={{fontSize:'16px'}}>City:</strong> {property.city}</p>
              <p style={{ fontSize: '16px' }}><strong style={{fontSize:'16px'}}>State:</strong> {property.state}</p>
              <Button size="md" variant="warning" onClick={() => setShowModal(true)}>Enquire</Button>
            </Col>
          </Row>

          {/* Additional Images */}
          <h2 className="text-center mt-4 mb-4" style={{ fontWeight: 'bold' }}>Additional Images</h2>
          <Row>
            {[property.additionalImage2, property.additionalImage3, property.additionalImage1].map((image, idx) => (
              <Col md={4} key={idx}>
                <img
                  src={`${BASE_URL}/${image}`}
                  alt={`Additional ${idx}`}
                  className="w-100"
                  style={{ height: '200px', objectFit: 'cover' }}
                  onClick={() => handleImageClick(image)} // On click, preview this image
                />
              </Col>
            ))}
          </Row>
        </div>
      </Container>

      {/* Enquiry Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enquire About This Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formMobile">
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your mobile number"
                    name="mobile"
                    value={formData.mobile}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,10}$/.test(value)) {
                        handleInputChange(e);
                      }
                    }}
                    required
                    minLength={10}
                    maxLength={10} 
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="formWhatsapp">
                  <Form.Label>Whatsapp</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your WhatsApp number"
                    name="whatsapp"
                    value={formData.whatsapp}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only 10 digits
                      if (/^\d{0,10}$/.test(value)) {
                        handleInputChange(e);
                      }
                    }}
                    required
                    minLength={10}
                    maxLength={10} 
                  />
                </Form.Group>
              </Col>

            </Row>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <Button variant="warning" style={{ color: "black" }} type="submit">
                Submit Enquiry
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Image Preview Modal */}
      {previewImage && (
        <Modal show={true} onHide={handleClosePreview} centered>
          <Modal.Header closeButton>
            <Modal.Title>Image Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ textAlign: 'center' }}>
              <img
                src={`${BASE_URL}/${previewImage}`}
                alt="Preview"
                style={{
                  width: '100%',  // Ensures the image fits within the modal
                  maxHeight: '400px', // Set a maximum height for the image
                  objectFit: 'contain', // Maintain aspect ratio
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}


      <Container fluid>
        <Row>
          <Footer />
        </Row>
      </Container>
    </>
  );
};

export default PropertyDetail;
