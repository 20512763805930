// import React, { useEffect, useState } from 'react';
// import { Row, Col, Card, Container } from 'react-bootstrap';
// import '../css/SunriseFront.css';
// import 'aos/dist/aos.css';
// import image1 from '../assests/1.png';
// import image2 from '../assests/2.png';
// import image3 from '../assests/3.png';
// import image4 from '../assests/4.png';
// import image5 from '../assests/5.png';
// import image6 from '../assests/image7.jpg';
// import NavHeader from './NavHeader';
// import Footer from './Footer';
// import EnquiryForm from './EnquiryForm';
// import { useNavigate } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify'; // Import Toastify components
// import 'react-toastify/dist/ReactToastify.css'; // Import styles
// import ToastNotification from '../ToastNotification';

// const SunriseFront = () => {
//   const navigate = useNavigate();

//   const handleCardClick = (text) => {
//     if (text === 'Property Buy & Sell') {
//       navigate('/buyproperty'); // Navigate to /buyproperty path
//     } else {
//       toast.info('Coming Soon!'); // Show toast for other cards
//     }
//   };

//   useEffect(() => {
//     document.title = "Sunrise Ventures";
//   }, []);

//   return (
//     <>
//       <NavHeader />
//       {/* Toast Container to display the toast message */}
//       <ToastNotification />
//       <Container fluid >
//         <Row style={{ marginTop: "3%" }}>
//           {/* Cards Section */}
//           <Col lg={8}>
//             <Row className="g-4">
//               {[{ img: image5, text: 'Property Buy & Sell' }, { img: image4, text: 'Agriculture' }, { img: image3, text: 'Drone' }].map((item, idx) => (
//                 <Col md={4} key={idx} data-aos="fade-up">
//                   <Card className="card-hover" onClick={() => handleCardClick(item.text)}>
//                     <img src={item.img} alt={item.text} />
//                     <div className="card-overlay">{item.text}</div>
//                   </Card>
//                 </Col>
//               ))}
//             </Row>
//             <Row className="g-4 mt-3">
//               {[{ img: image1, text: 'Global Opportunities' }, { img: image6, text: 'Alkaline Water' }, { img: image2, text: 'Farm Management' }].map((item, idx) => (
//                 <Col md={4} key={idx} data-aos="fade-up">
//                   <Card className="card-hover" onClick={() => handleCardClick(item.text)}>
//                     <img src={item.img} alt={item.text} />
//                     <div className="card-overlay">{item.text}</div>
//                   </Card>
//                 </Col>
//               ))}
//             </Row>
//           </Col>

//           {/* Enquiry Form Section */}
//           <Col lg={4}>
//             <EnquiryForm />
//           </Col>
//         </Row>
//       </Container>
//       <Footer />
//     </>
//   );
// };

// export default SunriseFront;


import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Container } from 'react-bootstrap';
import '../css/SunriseFront.css';
import 'aos/dist/aos.css';
import image1 from '../assests/1.png';
import image2 from '../assests/2.png';
import image3 from '../assests/3.png';
import image4 from '../assests/4.png';
import image5 from '../assests/5.png';
import image6 from '../assests/image7.jpg';
import NavHeader from './NavHeader';
import Footer from './Footer';
import EnquiryForm from './EnquiryForm';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify components
import 'react-toastify/dist/ReactToastify.css'; // Import styles
import ToastNotification from '../ToastNotification';

const SunriseFront = () => {
  const navigate = useNavigate();

  const handleCardClick = (text) => {
    if (text === 'Property Buy & Sell') {
      navigate('/buyproperty'); // Navigate to /buyproperty path
    } else {
      toast.info('Coming Soon!'); // Show toast for other cards
    }
  };

  useEffect(() => {
    document.title = "Sunrise Ventures";
  }, []);

  return (
    <div className="page-container">
      <NavHeader />
      
      {/* Toast Notification */}
      <ToastNotification />
      
      <Container fluid className="main-content">
        <Row style={{ marginTop: "3%" }}>
          {/* Cards Section */}
          <Col lg={8}>
            <Row className="g-4">
              {[{ img: image5, text: 'Property Buy & Sell' }, { img: image4, text: 'Agriculture' }, { img: image3, text: 'Drone' }].map((item, idx) => (
                <Col md={4} key={idx} data-aos="fade-up">
                  <Card className="card-hover" onClick={() => handleCardClick(item.text)}>
                    <img src={item.img} alt={item.text} />
                    <div className="card-overlay">{item.text}</div>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row className="g-4 mt-3">
              {[{ img: image1, text: 'Global Opportunities' }, { img: image6, text: 'Alkaline Water' }, { img: image2, text: 'Farm Management' }].map((item, idx) => (
                <Col md={4} key={idx} data-aos="fade-up">
                  <Card className="card-hover" onClick={() => handleCardClick(item.text)}>
                    <img src={item.img} alt={item.text} />
                    <div className="card-overlay">{item.text}</div>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>

          {/* Enquiry Form Section */}
          <Col lg={4}>
            <EnquiryForm />
          </Col>
        </Row>
      </Container>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default SunriseFront;
