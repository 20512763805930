



// import React, { useState } from 'react';
// import { Row, Col, Form, Button } from 'react-bootstrap';
// import { toast } from 'react-toastify'; // For notifications
// import axios from 'axios'; // Import Axios
// import { BASE_URL } from '../BaseURL'; // Base URL for API requests
// import ToastNotification from '../ToastNotification';

// const EnquiryForm = () => {
//     const [formData, setFormData] = useState({
//         name: '',
//         city: '',
//         state: '',
//         phone: '',
//         whatsApp: '',
//         email: '',
//     });

//     const handleChange = (e) => {
//         const { name, value } = e.target;

//         // Handle phone and WhatsApp validations
//         if (name === 'phone' || name === 'whatsApp') {
//             if (/^\d{0,10}$/.test(value)) {
//                 setFormData((prevData) => ({ ...prevData, [name]: value }));
//             }
//         } else {
//             setFormData((prevData) => ({ ...prevData, [name]: value }));
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             // Make an Axios POST request
//             const response = await axios.post(`${BASE_URL}/userenquiry/addenquiry`, formData);

//             if (response?.data?.success) {
//                 toast.success('Enquiry submitted successfully!');
//                 setFormData({
//                     name: '',
//                     city: '',
//                     state: '',
//                     phone: '',
//                     whatsApp: '',
//                     email: '',
//                 });
//             } else {
//                 toast.error(response?.data?.message || 'Failed to submit enquiry. Please try again.');
//             }
//         } catch (error) {
//             console.error('Error submitting enquiry:', error);
//             toast.error('Error submitting enquiry. Please try again.');
//         }
//     };

//     return (
//         <>
//             <ToastNotification />
//             <div className="enquiry-form p-3 rounded bg-light">
//                 <h4 className="mb-3">Enquiry Form</h4>
//                 <Form onSubmit={handleSubmit}>
//                     <Row>
//                         <Col lg={6} md={6} sm={6}>
//                             <Form.Group controlId="formName" className="mb-3">
//                                 <Form.Label>Name</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     name="name"
//                                     value={formData.name}
//                                     onChange={handleChange}
//                                     placeholder="Enter Your Name"
//                                 />
//                             </Form.Group>
//                         </Col>
//                         <Col lg={6} md={6} sm={6}>
//                             <Form.Group controlId="formEmail" className="mb-3">
//                                 <Form.Label>Email</Form.Label>
//                                 <Form.Control
//                                     type="email"
//                                     name="email"
//                                     value={formData.email}
//                                     onChange={handleChange}
//                                     placeholder="Enter Email"
//                                 />
//                             </Form.Group>
//                         </Col>
//                         <Col lg={6} md={6} sm={6}>
//                             <Form.Group controlId="formPhone" className="mb-3">
//                                 <Form.Label>Phone No.</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     name="phone"
//                                     value={formData.phone}
//                                     onChange={handleChange}
//                                     placeholder="Enter Phone Number"
//                                 />
//                             </Form.Group>
//                         </Col>
//                         <Col lg={6} md={6} sm={6}>
//                             <Form.Group controlId="formWhatsApp" className="mb-3">
//                                 <Form.Label>WhatsApp No.</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     name="whatsApp"
//                                     value={formData.whatsApp}
//                                     onChange={handleChange}
//                                     placeholder="Enter WhatsApp Number"
//                                 />
//                             </Form.Group>
//                         </Col>
//                         <Col lg={6} md={6} sm={6}>
//                             <Form.Group controlId="formCity" className="mb-3">
//                                 <Form.Label>City</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     name="city"
//                                     value={formData.city}
//                                     onChange={handleChange}
//                                     placeholder="Enter City"
//                                 />
//                             </Form.Group>
//                         </Col>

//                         <Col lg={6} md={6} sm={6}>
//                             <Form.Group controlId="formState" className="mb-3">
//                                 <Form.Label>State</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     name="state"
//                                     value={formData.state}
//                                     onChange={handleChange}
//                                     placeholder="Enter State"
//                                 />
//                             </Form.Group>
//                         </Col>

//                     </Row>

//                     <Button
//                         variant="warning"
//                         style={{ fontWeight: 'bold' }}
//                         type="submit"
//                         className="w-30 text-dark d-flex justify-content-center mx-auto">
//                         Send Enquiry
//                     </Button>
//                 </Form>
//             </div>
//         </>
//     );
// };

// export default EnquiryForm;


import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify'; // For notifications
import axios from 'axios'; // Import Axios
import { BASE_URL } from '../BaseURL'; // Base URL for API requests
import ToastNotification from '../ToastNotification';

const EnquiryForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        city: '',
        state: '',
        phone: '',
        whatsApp: '',
        email: '',
        comment:'',
    });

    // Static list of Indian states
    const states = [
        'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 
        'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 
        'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 
        'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 
        'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 
        'Uttar Pradesh', 'Uttarakhand', 'West Bengal'
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Handle phone and WhatsApp validations
        if (name === 'phone' || name === 'whatsApp') {
            if (/^\d{0,10}$/.test(value)) {
                setFormData((prevData) => ({ ...prevData, [name]: value }));
            }
        } else {
            setFormData((prevData) => ({ ...prevData, [name]: value }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Make an Axios POST request
            const response = await axios.post(`${BASE_URL}/userenquiry/addenquiry`, formData);

            if (response?.data?.success) {
                toast.success('Enquiry submitted successfully!');
                setFormData({
                    name: '',
                    city: '',
                    state: '',
                    phone: '',
                    whatsApp: '',
                    email: '',
                    comment:'',
                });
            } else {
                toast.error(response?.data?.message || 'Failed to submit enquiry. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting enquiry:', error);
            toast.error('Error submitting enquiry. Please try again.');
        }
    };

    return (
        <>
            <ToastNotification />
            <div className="enquiry-form p-3 rounded bg-light">
                <h4 className="mb-3">Enquiry Form</h4>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col lg={6} md={6} sm={6}>
                            <Form.Group controlId="formName" className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Enter Your Name"
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                            <Form.Group controlId="formEmail" className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Enter Email"
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                            <Form.Group controlId="formPhone" className="mb-3">
                                <Form.Label>Mobile No.</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    placeholder="Enter Phone Number"
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                            <Form.Group controlId="formWhatsApp" className="mb-3">
                                <Form.Label>WhatsApp No.</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="whatsApp"
                                    value={formData.whatsApp}
                                    onChange={handleChange}
                                    placeholder="Enter WhatsApp Number"
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                            <Form.Group controlId="formCity" className="mb-3">
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    placeholder="Enter City"
                                />
                            </Form.Group>
                        </Col>

                        {/* State Dropdown */}
                        <Col lg={6} md={6} sm={6}>
                            <Form.Group controlId="formState" className="mb-3">
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                >
                                    <option value="">Select State</option>
                                    {states.map((state, index) => (
                                        <option key={index} value={state}>
                                            {state}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                    <Col lg={8} md={6} sm={6}>
    <Form.Group controlId="formcomment" className="mb-3">
        <Form.Label>Comment</Form.Label>
        <Form.Control
            as="textarea" // Specify the control as a textarea
            rows={2} // Set the number of rows to 2
            name="comment"
            value={formData.comment}
            onChange={handleChange}
            placeholder="Enter Comments"
        />
    </Form.Group>
</Col>
                    </Row>

                    <Button
                        variant="warning"
                        style={{ fontWeight: 'bold' }}
                        type="submit"
                        className="w-30 text-dark d-flex justify-content-center mx-auto">
                        Send Enquiry
                    </Button>
                </Form>
            </div>
        </>
    );
};

export default EnquiryForm;
