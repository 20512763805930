import React, { useEffect } from 'react';
import { Row, Col, Container, Card, Button } from 'react-bootstrap';
import { FaBuilding, FaUsers, FaCheckCircle } from 'react-icons/fa';
import '../css/AboutUs.css'; // Make sure to create this CSS file
import AOS from 'aos';
import image1 from '../assests/aboutUs 2.webp';
import NavHeader from './NavHeader';
import Footer from './Footer';

const AboutUs = () => {
    useEffect(() => {
        document.title="Sunrise Ventures"
        // Initialize AOS for animations
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <>
            <NavHeader />
            <div className="about-us-container">
                {/* About Us Section */}
                <div className="about-us-header" data-aos="fade-in">
                    <h2>ABOUT US</h2>
                </div>

                <Container>
                    {/* Section with image and text */}
                    <Row className="my-5 align-items-center">
                        {/* First Column: Image */}
                        <Col lg={6} md={6} sm={12} className="about-us-image" data-aos="fade-right">
                            <img src={image1} alt="Company" className="img-fluid" />
                        </Col>

                        {/* Second Column: Text */}
                        <Col lg={6} md={6} sm={12} className="about-us-text" data-aos="fade-left">
                            <h2>
                                <span style={{ color: '#FF8C00', fontSize: '2rem' }}>ABOUT </span>
                                <span style={{ color: 'black', fontSize: '2rem' }}>COMPANY</span>
                            </h2>
                            <p>
                                We are a premier real estate company offering a wide range of properties for sale, rent, and investment opportunities.
                                Our mission is to empower individuals and businesses by providing them with top-tier real estate solutions that suit their needs,
                                lifestyle, and financial goals.
                            </p>
                            <p>
                                Our team of experts is dedicated to guiding clients through the often complex world of real estate, making the process
                                smooth and hassle-free. We take pride in understanding each client’s unique requirements and providing personalized advice
                                and recommendations.
                            </p>
                        </Col>
                    </Row>

                    {/* Section for 3 Cards */}
                    <Row className="mt-5">
                        {/* Card 1: Why Choose Us */}
                        <Col lg={4} md={4} sm={12} className="mb-4" data-aos="fade-up">
                            <Card className="about-card shadow-lg">
                                <Card.Body className="text-center">
                                    <FaBuilding size={50} color="#FF8C00" />
                                    <Card.Title className="m-4" style={{ fontSize: "1.5rem", fontWeight: "bold" }}>Why Choose Us?</Card.Title>
                                    <Card.Text className="m-4" style={{ fontSize: "1.2rem" }}>
                                        We offer expert real estate solutions and ensure the best deals for you, whether buying or selling.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Card 2: Our Solution */}
                        <Col lg={4} md={4} sm={12} className="mb-4" data-aos="fade-up" data-aos-delay="200">
                            <Card className="about-card shadow-lg">
                                <Card.Body className="text-center">
                                    <FaCheckCircle size={50} color="#FF8C00" />
                                    <Card.Title className="m-4" style={{ fontSize: "1.5rem", fontWeight: "bold" }}>Our Solution</Card.Title>
                                    <Card.Text className="m-4" style={{ fontSize: "1.2rem" }}>
                                        Our tailored solutions help clients achieve their real estate goals through exceptional services and expert advice.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Card 3: Our Expertise */}
                        <Col lg={4} md={4} sm={12} className="mb-4" data-aos="fade-up" data-aos-delay="400">
                            <Card className="about-card shadow-lg">
                                <Card.Body className="text-center">
                                    <FaUsers size={50} color="#FF8C00" />
                                    <Card.Title className="m-4" style={{ fontSize: "1.5rem", fontWeight: "bold" }}>Our Expertise</Card.Title>
                                    <Card.Text className="m-4" style={{ fontSize: "1.2rem" }}>
                                        With years of experience in the real estate market, we bring valuable insights and a deep understanding of client needs.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

            <Footer />
        </>
    );
};

export default AboutUs;
