import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaGlobe, FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/ContactUs.css';
import EnquiryForm from './EnquiryForm';
import NavHeader from './NavHeader';
import Footer from './Footer';
import { FaPhoneAlt } from "react-icons/fa";
import { toast } from 'react-toastify';
import axios from 'axios';
import { BASE_URL } from '../BaseURL';
import ToastNotification from '../ToastNotification';


const ContactUs = () => {
    useEffect(() => {
        document.title = "Sunrise Ventures"
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
        });
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        whatsApp: '',
        city: '',
        state: '',
        comment: '',
    });

    // Static list of Indian states
    const states = [
        'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh',
        'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand',
        'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur',
        'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab',
        'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura',
        'Uttar Pradesh', 'Uttarakhand', 'West Bengal'
    ];

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;

        // Handle phone and WhatsApp validations
        if (name === 'phone' || name === 'whatsApp') {
            if (/^\d{0,10}$/.test(value)) {
                setFormData((prevData) => ({ ...prevData, [name]: value }));
            }
        } else {
            setFormData((prevData) => ({ ...prevData, [name]: value }));
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Make an Axios POST request
            const response = await axios.post(`${BASE_URL}/userenquiry/addenquiry`, formData);

            if (response?.data?.success) {
                toast.success('Request submitted successfully!');
                setFormData({
                    name: '',
                    city: '',
                    state: '',
                    phone: '',
                    whatsApp: '',
                    email: '',
                    comment:'',
                });
            } else {
                toast.error(response?.data?.message || 'Failed to submit enquiry. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting enquiry:', error);
            toast.error('Error submitting enquiry. Please try again.');
        }
    };


    return (
        <div>
            <NavHeader />
            <ToastNotification />
            {/* Top Section with Background Image */}
            <div className="header-section">
                <div className="header-overlay">
                    <h1 className="header-title" data-aos="fade-down">Contact Us</h1>
                </div>
            </div>

            {/* Main Content */}
            <Container className="p-5 " style={{ overflow: 'hidden' }}>
                <Row>
                    {/* Left Column: Enquiry Form */}
                    <Col lg={8} md={12} className="mb-4" data-aos="fade-right">
                        <div className="enquiry-form p-3 rounded bg-light">
                            <h4 className="mb-3">REQUEST INFO</h4>
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col lg={6} md={6} sm={6}>
                                        <Form.Group controlId="formName" className="mb-3">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                placeholder="Enter Your Name"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Form.Group controlId="formEmail" className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                placeholder="Enter Email"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Form.Group controlId="formPhone" className="mb-3">
                                            <Form.Label>Mobile No.</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                placeholder="Enter Phone Number"
                                                minLength={10}
                                                maxLength={10}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Form.Group controlId="formWhatsApp" className="mb-3">
                                            <Form.Label>WhatsApp No.</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="whatsApp"
                                                value={formData.whatsApp}
                                                onChange={handleChange}
                                                placeholder="Enter WhatsApp Number"
                                                minLength={10}
                                                maxLength={10}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Form.Group controlId="formCity" className="mb-3">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="city"
                                                value={formData.city}
                                                onChange={handleChange}
                                                placeholder="Enter City"
                                            />
                                        </Form.Group>
                                    </Col>

                                    {/* State Dropdown */}
                                    <Col lg={6} md={6} sm={6}>
                                        <Form.Group controlId="formState" className="mb-3">
                                            <Form.Label>State</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="state"
                                                value={formData.state}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select State</option>
                                                {states.map((state, index) => (
                                                    <option key={index} value={state}>
                                                        {state}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    {/* Budget Dropdown for Real Estate */}
                                    {/* <Col lg={6} md={6} sm={6}>
                                        <Form.Group controlId="formBudget" className="mb-3">
                                            <Form.Label>Budget</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="budget"
                                                value={formData.budget}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Budget</option>
                                                <option value="Up to 5 Crore">Up to 5 Crore</option>
                                                <option value="Up to 10 Crore">Up to 10 Crore</option>
                                                <option value="Up to 20 Crore">Up to 20 Crore</option>
                                                <option value="1 Crore">1 Crore</option>
                                                <option value="2 Crore">2 Crore</option>
                                                <option value="3 Crore">3 Crore</option>
                                                <option value="Below 1 Crore">Below 1 Crore</option>
                                                <option value="Up to 80 Lakhs">Up to 80 Lakhs</option>
                                                <option value="Up to 50 Lakhs">Up to 50 Lakhs</option>
                                                <option value="Below 10 Lakhs">Below 10 Lakhs</option>
                                                <option value="Below 20 Lakhs">Below 20 Lakhs</option>
                                                <option value="Below 50 Lakhs">Below 50 Lakhs</option>
                                                <option value="Up to 5 Lakhs">Up to 5 Lakhs</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col> */}

<Col lg={8} md={6} sm={6}>
    <Form.Group controlId="formcomment" className="mb-3">
        <Form.Label>Comment</Form.Label>
        <Form.Control
            as="textarea" // Specify the control as a textarea
            rows={2} // Set the number of rows to 2
            name="comment"
            value={formData.comment}
            onChange={handleChange}
            placeholder="Enter Comments"
        />
    </Form.Group>
</Col>

                                </Row>

                                <Button
                                    variant="warning"
                                    style={{ fontWeight: 'bold' }}
                                    type="submit"
                                    className="w-30 text-dark d-flex justify-content-center mx-auto">
                                    Send Enquiry
                                </Button>
                            </Form>
                        </div>
                    </Col>

                    {/* Right Column: Get In Touch */}
                    <Col lg={4} md={12} data-aos="fade-left">
                        <div className="get-in-touch-wrapper p-4 rounded shadow">
                            <h4 className="section-title mb-4">Get In Touch</h4>
                            <p className="description mt-2">
                                Feel free to get in touch with us for any inquiries or assistance. We're here to help!
                            </p>
                            <div className="contact-item mb-3 d-flex align-items-center">
                                <FaGlobe className="contact-icon me-3" />
                                <p style={{ textAlign: "justify" }}>UG 48, Ansal Chambers 2, <br /> Near Bank Of Baroda Bikaji <br /> Cama Place New Delhi - 110066</p>
                            </div>
                            <div className="contact-item mb-3 d-flex align-items-center">
                                <FaPhoneAlt className="contact-icon me-3" />
                                <p>9910511007</p>
                            </div>
                            <div className="contact-item mb-3 d-flex align-items-center">
                                <FaEnvelope className="contact-icon me-3" />
                                <p>faujiteams@gmail.com</p>
                            </div>

                            {/* <div className="social-icons mt-4 d-flex mt-2">
                                <div className="social-icon-wrapper" data-aos="zoom-in" data-aos-delay="100">
                                    <FaFacebook className="social-icon" />
                                </div>
                                <div className="social-icon-wrapper" data-aos="zoom-in" data-aos-delay="200">
                                    <FaTwitter className="social-icon" />
                                </div>
                                <div className="social-icon-wrapper" data-aos="zoom-in" data-aos-delay="300">
                                    <FaInstagram className="social-icon" />
                                </div>
                            </div> */}
                        </div>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </div>
    );
};

export default ContactUs;
