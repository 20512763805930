import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo from '../assests/sunriseLogo.png'; // Adjust the path to your logo
import '../css/NavHeader.css'; // Import the CSS file

export default function NavHeader() {
  const navigate = useNavigate();


  return (
    <Navbar
      className="navbar p-3 sticky-top "
      expand="lg"
      style={{
        width:'100%',
        position: 'sticky',       // Make the navbar sticky
        top: 0,                   // Stick it to the top
        zIndex: 1000,             // Ensure it stays above other content
        backgroundColor: '#fff',  // Light background color
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Box shadow at the bottom
      }}
    >

      <Container fluid>
        <div className="d-flex align-items-center">
          {/* Logo click navigates to Home */}
          <div onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
            <img
              src={logo}
              alt="Logo"
              style={{ height: '60px', width: '75%', marginLeft: '20px' }}
              className="me-3"
            />
          </div>
        </div>
        <Navbar.Toggle aria-controls="navbarNav" />
        <Navbar.Collapse id="navbarNav">
          <Nav className="ms-auto">
            <div className="nav-link" onClick={() => navigate('/')}>Home</div>
            <div className="nav-link" onClick={() => navigate('/about')}>About Us</div>
            {/* <div className="nav-link" onClick={() => navigate('/sellproperty')}>Sell Property</div>
            <div className="nav-link" onClick={() => navigate('/buyproperty')}>Buy Property</div> */}
            <div className="nav-link" onClick={() => navigate('/contact')}>Contact Us</div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
