import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Container, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from '../BaseURL';
import ToastNotifications from '../ToastNotification';
import '../css/Form.css';
import NavHeader from './NavHeader';
import Footer from './Footer';

const SellProperty = () => {
    const [formData, setFormData] = useState({
        ownerName: '',
        ownerMobile: '',
        ownerEmail: '',
        ownerWhatsapp: '',
        propertyName: '',
        propertyType: '',
        area: '',  // Default area set to 50
        areaMeasurement: '',  // Default area measurement set to hectares
        state: '',
        city: '',
        address: '',
        sellingPrice: '',
    });

    const [images, setImages] = useState({
        primaryImage: null,
        additionalImage1: null,
        additionalImage2: null,
        additionalImage3: null,
    });

    const [propertyTypes, setPropertyTypes] = useState([]);
    const [areaMeasurements, setAreaMeasurements] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fetchingData, setFetchingData] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const propertyRes = await axios.get(`${BASE_URL}/sellproperty/getallproperties`);
                const areaRes = await axios.get(`${BASE_URL}/sellproperty/getallareas`);
                setPropertyTypes(Array.isArray(propertyRes?.data) ? propertyRes.data : []);
                setAreaMeasurements(Array.isArray(areaRes?.data) ? areaRes.data : []);
            } catch (error) {
                toast.error('Error fetching dynamic data.');
            } finally {
                setFetchingData(false);
            }
        };

        document.title = "Sunrise Ventures"
        fetchData();
    }, []);

    // List of states in India
    const indianStates = [
        "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat",
        "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra",
        "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu",
        "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal", "Andaman and Nicobar Islands",
        "Chandigarh", "Dadra and Nagar Haveli and Daman and Diu", "Lakshadweep", "Delhi", "Puducherry"
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (files[0]) {
            setImages((prev) => ({ ...prev, [name]: files[0] }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Basic validation
        if (!formData.ownerName || !formData.ownerMobile || !formData.ownerEmail || !formData.propertyType || !formData.area || !formData.areaMeasurement || !formData.state || !formData.city || !formData.address || !formData.sellingPrice) {
            toast.error('Please fill all required fields.');
            setLoading(false);
            return;
        }

        try {
            const data = new FormData();
            Object.entries(formData).forEach(([key, value]) => data.append(key, value));

            // Append images to form data
            if (images.primaryImage) data.append('primaryImage', images.primaryImage);
            if (images.additionalImage1) data.append('additionalImage1', images.additionalImage1);
            if (images.additionalImage2) data.append('additionalImage2', images.additionalImage2);
            if (images.additionalImage3) data.append('additionalImage3', images.additionalImage3);

            // Sending the request to add the property
            const response = await axios.post(`${BASE_URL}/sellproperty/addSellProperty`, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            // Check if response has a success property
            if (response?.data?.success) {
                toast.success('Property listed for sale successfully!');

                // Wait for 1 second before navigating
                setTimeout(() => {
                    navigate('/');  // Redirect to home or another page after success
                }, 2000);  // 1000ms (1 second) delay
            } else {
                toast.error(response?.data?.message || 'Error listing property.');
            }
        } catch (error) {
            console.error('Error: ', error);  // Log error for debugging
            toast.error(error?.response?.data?.message || 'Internal server error.');
        } finally {
            setLoading(false);  // Ensure loading state is reset after operation
        }
    };



    return (
        <>
            <NavHeader />
            <ToastNotifications />
            <div className="sellproperty" data-aos="fade-down">
                <div className="header-overlay">
                    <h1 className="header-title">SELL Property</h1>
                </div>
            </div>
            <Container fluid className="mt-4">
                <div className="form-container shadow p-4 rounded">
                    <h4 className="text-center">Property Details</h4>
                    {fetchingData ? (
                        <div className="text-center">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    ) : (
                        <Form onSubmit={handleSubmit}>
                            {/* First Row: Owner Details and Property Details */}
                            <Row>

                                <Col md={6} className='mt-3'>
                                    <h5 className="add-branch-header">Owner Details</h5>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group controlId="ownerName">
                                                <Form.Label>Owner Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Owner Name"
                                                    name="ownerName"
                                                    value={formData.ownerName}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>  <Form.Group controlId="ownerMobile">
                                            <Form.Label>Owner Mobile</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Owner Mobile"
                                                name="ownerMobile"
                                                value={formData.ownerMobile}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d{0,10}$/.test(value)) {
                                                        handleChange(e);
                                                    }
                                                }}

                                                minLength={10}
                                                maxLength={10}
                                                required
                                            />
                                        </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group controlId="ownerEmail">
                                                <Form.Label>Owner Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Enter Owner Email"
                                                    name="ownerEmail"
                                                    value={formData.ownerEmail}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>

                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group controlId="ownerWhatsapp">
                                                <Form.Label>Owner Whatsapp</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Owner Whatsapp"
                                                    name="ownerWhatsapp"
                                                    value={formData.ownerWhatsapp}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d{0,10}$/.test(value)) {
                                                            handleChange(e);
                                                        }
                                                    }}
                                                    minLength={10}
                                                    maxLength={10}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={6} className='mt-3'>
                                    <h5 className="add-branch-header">Property Details</h5>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group controlId="propertyName">
                                                <Form.Label>Property Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Property Name"
                                                    name="propertyName"
                                                    value={formData.propertyName}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group controlId="propertyType">
                                                <Form.Label>Property Type</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="propertyType"
                                                    value={formData.propertyType}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select Property Type</option>
                                                    {propertyTypes.length > 0 ? (
                                                        propertyTypes.map((type) => (
                                                            <option key={type.propertyId} value={type.propertyType}>
                                                                {type.propertyType}
                                                            </option>
                                                        ))
                                                    ) : (
                                                        <option disabled>No Property Types Available</option>
                                                    )}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group controlId="sellingPrice">
                                                <Form.Label>Selling Price</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter Selling Price"
                                                    name="sellingPrice"
                                                    value={formData.sellingPrice}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group controlId="area">
                                                        <Form.Label>Area (in units)</Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            name="area"
                                                            value={formData.area}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="areaMeasurement">
                                                        <Form.Label>Measurement</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            name="areaMeasurement"
                                                            value={formData.areaMeasurement}
                                                            onChange={handleChange}
                                                            required
                                                        >
                                                            <option value="">Select Area Measurement</option>
                                                            {areaMeasurements.length > 0 ? (
                                                                areaMeasurements.map((measurement) => (
                                                                    <option key={measurement.areaId} value={measurement.areaMeasurement}>
                                                                        {measurement.areaMeasurement}
                                                                    </option>
                                                                ))
                                                            ) : (
                                                                <option disabled>No Area Measurements Available</option>
                                                            )}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>

                            {/* Second Row: Address Details and Upload Property Images */}
                            <Row className='mt-3'>
                                <Col md={6}>
                                    <h5 className="add-branch-header">Address Details</h5>
                                    <Row>
                                        <Col lg={6}> <Form.Group controlId="state">
                                            <Form.Label>State</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="state"
                                                value={formData.state}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select State</option>
                                                {indianStates.map((state, index) => (
                                                    <option key={index} value={state}>
                                                        {state}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group controlId="city">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter City"
                                                    name="city"
                                                    value={formData.city}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}> <Form.Group controlId="address">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Address"
                                                name="address"
                                                value={formData.address}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={6}>
                                    <h5 className="add-branch-header">Upload Property Images</h5>
                                    <Row>
                                        <Col lg={6}>   <Form.Group controlId="primaryImage">
                                            <Form.Label>Primary Image</Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="primaryImage"
                                                onChange={handleFileChange}
                                                required
                                            />
                                        </Form.Group>

                                        </Col>
                                        <Col lg={6}>     <Form.Group controlId="additionalImage1">
                                            <Form.Label>Additional Image 1</Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="additionalImage1"
                                                onChange={handleFileChange}
                                            />
                                        </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>  <Form.Group controlId="additionalImage2">
                                            <Form.Label>Additional Image 2</Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="additionalImage2"
                                                onChange={handleFileChange}
                                            />
                                        </Form.Group>

                                        </Col>
                                        <Col lg={6}>    <Form.Group controlId="additionalImage3">
                                            <Form.Label>Additional Image 3</Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="additionalImage3"
                                                onChange={handleFileChange}
                                            />
                                        </Form.Group>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>

                            {/* Submit Button */}
                            <div className="text-center mt-3">
                                <Button variant="warning" style={{ color: 'black', fontWeight: "bold" }} type="submit" size="md" disabled={loading}>
                                    {loading ? 'Listing...' : 'Submit'}
                                </Button>
                            </div>
                        </Form>
                    )}
                </div>
            </Container>

            <Footer />
        </>

    );
};

export default SellProperty;
