// src/App.js

import React, { useState, useEffect } from 'react';
import { HashRouter  as Router, Route, Routes } from 'react-router-dom';
import SunriseFront from './pages/SunriseFront';
import Loader from './pages/Loder'; // Import the loader component
import 'bootstrap/dist/css/bootstrap.min.css';  // For styles
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // For JavaScript functionality
import AOS from 'aos';
import 'aos/dist/aos.css';
import SellProperty from './pages/SellProperty';
import BuyProperty from './pages/BuyProperty';
import PropertyDetail from './pages/PropertyDetail';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import axios from 'axios';
import { BASE_URL } from './BaseURL';


AOS.init({
  duration: 800, // Animation duration
  easing: 'ease-in-out', // Easing function
  once: true, // Animation happens only once
});

function App() {
  const [loading, setLoading] = useState(true);

  // Simulate loading delay, set loading to false after 3 seconds
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    },1000); // Loader will display for 3 seconds

    let userId = localStorage.getItem('userId');  // This retrieves the 'userId' from localStorage

    // If there's no userId in Local Storage, it's the user's first visit
    if (!userId) {
      // Generate a unique ID for the user (e.g., using a simple timestamp or a UUID)
      userId = Date.now().toString();  // Simple unique ID (you can use a more robust method like UUID)

      // Store the userId in Local Storage
      localStorage.setItem('userId', userId);

      // Send a POST request to the backend to increment the visitor count
      axios.post(`${BASE_URL}/visitor/increment`, { userId })
        .then((response) => {
          console.log('Visitor count incremented', response.data);
        })
        .catch((error) => {
          console.error('Error incrementing visitor count', error);
        });
    } else {
      // If the userId exists, do nothing (visitor already counted)
      console.log('User already counted');
    }

  }, []);


  
  return (
    <Router>
      {loading ? (
        <Loader /> // Show the loader during loading state
      ) : (
        <Routes>
          <Route path="/" element={<SunriseFront />} />
          <Route path="/sellproperty" element={<SellProperty />} />
          <Route path="/buyproperty" element={<BuyProperty />} />
          <Route path="/property/:propertyName/:addPropertyId" element={<PropertyDetail />} />
          <Route path="/about" element={<AboutUs/>} />
          <Route path="/contact" element={<ContactUs/>} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
